<template>
  <font-awesome-icon
    :icon="[type, icon]"
    :size="size"
    :title="title"
    :style="{ color: color }"
  />
</template>

<script>
export default {
  name: "Icon",
  props: {
    type: {
      type: String,
      default: 'fas',
      validator: (value) => {
        return ['fas', 'far', 'fab'].includes(value)
      }
    },
    icon: {
      type: String,
      required: true,
      default: 'font-awesome' // https://fontawesome.com/search?ic=free
    },
    size: {
      type: String,
      default: '1x',
            validator(value) {
        const allowedSizes = [
          '2xs',
          'xs',
          'sm',
          'lg',
          'xl',
          '2xl',
          '1x',
          '2x',
          '3x',
          '4x',
          '5x',
          '6x',
          '7x',
          '8x',
          '9x',
          '10x'
        ]
        return allowedSizes.includes(value)
      }
    },
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'white',
    },
  }
}
</script>
