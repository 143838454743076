import axios from "axios";

import $ from "jquery";

const api = axios.create({
    baseURL: process.env.APP_URL,
    headers: {
        "X-Custom-Header": $('meta[name="csrf-token"]').attr("content"),
    },
});

export default api;
