<template>
  <div
    :class="['modal', 'fade', myClass]"
    :id="modalId"
    tabindex="-1"
    :aria-labelledby="modalId"
  >
    <div :class="['modal-dialog', 'modal-dialog-scrollable', dialogClass]">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="modalId">
            {{ title }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body modal-body-heigth">
          <slot name="body"/>
        </div>

        <div class="modal-footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    modalId: {
      type: String,
      default: '',
    },
    myClass: {
      type: String,
      default: '',
    },
    dialogClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    modalBody: {
      type: String,
      default: '',
    },
    modalFooter: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      $('#'+this.modalId).modal('hide')
    },
  },
};
</script>
