import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import $ from "jquery";
window.$ = $;
window.jQuery = $;

import EmailConversa from "./components/vue/email/EmailConversa.vue";
import ModalResposta from "./components/vue/email/ModalResposta.vue";

Vue.config.productionTip = false;

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("email-conversa", EmailConversa);
Vue.component("email-resposta", ModalResposta);

Vue.use(CKEditor);

const app = new Vue({}).$mount("#app");
