<template>
  <div :class="['card', 'mb-3', 'mr-3', email.enviado ? 'card-user' : 'card-client']">
    <!-- Cabeçalho -->
    <div class="card-header d-flex justify-content-between align-items-center">
      <div>
        <div>
          <strong v-if="email.enviado">
            Você ({{ email.from_email }})
          </strong>
          <strong v-else>
            {{ email.from_name }} ({{ email.from_email }})
          </strong>
          <small class="text-muted"> – {{ formattedDate }}</small>
        </div>
        <div v-if="email.cc">
          <small class="text-muted">Cc: {{ email.cc }}</small>
        </div>
      </div>

      <!-- Ícone no lado direito do cabeçalho -->
      <div class="position-relative d-inline-block" :id="'icon-wrapper-' + email.id">
        <template v-if="email.enviado">
          <i class="fa fa-paper-plane text-muted fa-lg" aria-hidden="true" title="Email enviado" />
        </template>

        <template v-else>
          <a href="javascript:void(0)" @click="!isUpdating && markReadUnread(email.id)">
            <Icon
              v-if="email.lido"
              class="text-muted"
              type="far"
              icon="envelope-open"
              size="lg"
              title="Clique para marcar como não lido"
              color="white"
            />
            <Icon
              v-else
              class="text-muted"
              type="far"
              icon="envelope"
              size="lg"
              title="Clique para marcar como lido"
              color="white"
            />
          </a>
        </template>

        <span v-if="!email.lido" class="badge-unread"></span>
      </div>
    </div>

    <!-- Corpo (conteúdo da mensagem) -->
    <div class="card-body">
      <span v-if="!readMoreOpen" v-html="previewText"></span>

      <div style="white-space: pre-wrap;" v-if="readMoreOpen" class="mt-2" v-html="email.body"></div>

      <div v-if="isBodyLong" class="mt-2">
        <a class="btn btn-link p-0" href="javascript:void(0)" @click="toggleReadMore">
          {{ readMoreOpen ? 'Ler menos' : 'Ler mais' }}
        </a>
      </div>

      <!-- Anexos -->
      <div v-if="attachments.length > 0">
        <hr />
        <p class="mb-2"><strong>Anexos:</strong></p>
        <div v-for="(attachment, index) in attachments" :key="index">
          <i class="fa fa-paperclip mr-1" aria-hidden="true" />
          <a href="javascript:void(0)" @click="downloadAttachment(attachment)">
            {{ attachment.filename }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/api';

import Icon from '../Icon.vue';

export default {
  name: 'EmailMsg',
  components: {
    Icon,
  },
  props: {
    email: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      limit: 150,
      readMoreOpen: false,
      isUpdating: false
    };
  },
  computed: {
    previewText() {
      let cleanText = this.stripTags(this.email.body);
      cleanText = this.truncateText(cleanText, this.limit, '...');
      return this.closeOpenPTags(cleanText);
    },
    isBodyLong() {
      const cleanText = this.stripTags(this.email.body);
      return cleanText.length > this.limit;
    },
    attachments() {
      let result = [];
      if (this.email.attachments) {
        try {
          const parsed = JSON.parse(this.email.attachments);
          if (Array.isArray(parsed)) {
            result = parsed;
          }
        } catch (error) {
          console.error('Erro ao fazer parse dos anexos:', error);
        }
      }
      return result;
    },
    formattedDate() {
      if (!this.email.date) return '';
      return moment(this.email.date).format('DD/MM/YYYY HH:mm');
    }
  },
  methods: {
    stripTags(html) {
      if (!html) return '';
      return html.replace(/<(?!\/?(br|p)\b)[^>]*>/g, ''); // Mantém <br> e <p>
    },
    truncateText(text, length = 150, suffix = '...') {
      if (!text) return '';

      if (text.length <= length) return text;

      let truncated = text.substring(0, length);

      return truncated + suffix;
    },
    closeOpenPTags(text) {
      const openPCount = (text.match(/<p>/g) || []).length;
      const closePCount = (text.match(/<\/p>/g) || []).length;

      if (openPCount > closePCount) {
        text += '</p>'; // Fecha a tag pendente
      }

      return text;
    },
    toggleReadMore() {
      this.readMoreOpen = !this.readMoreOpen;
    },
    downloadAttachment(attachment) {
      const dados = {
        params: {
          mimeType: attachment.mimeType,
          filename: attachment.filename
        }
      };

      api.get(`/painel/oportunidades-email-download/${this.email.id}/${attachment.attachmentId}`, dados)
      .then(response => {
          let fileName = 'attachment';
          const disposition = response.headers['content-disposition'];
          if (disposition) {
            const matches = /filename="([^"]+)"/.exec(disposition);
            if (matches && matches[1]) {
              fileName = matches[1];
            }
          }

          const blob = new Blob([response.data], {
            type: response.headers['content-type']
          });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          link.remove();
          window.URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          console.error('Download failed:', error);
        });
    },
    markReadUnread(emailId) {
      if (this.isUpdating) return;
      this.isUpdating = true;
      api.put(`/painel/oportunidades-email-read-unread/${emailId}`)
        .then((response) => {
          if (!response.data.error) {
            window.showToastSuccess('body', 'Email alterado com sucesso');
            this.$emit('toggle-read', emailId);
          } else {
            window.showToastDanger('body', response.data.message);
          }
        })
        .catch(() => {
          window.showToastDanger('body', 'Não foi possível alterar o email');
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
  }
};
</script>