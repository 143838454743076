<template>
  <div>
    <Modal
      modalId="modalResposta"
      title="Responder Email"
      dialogClass="modal-lg"
    >
      <template #body>
        <div class="form-group">
          <label for="toEmail">Para:</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="toEmail"
            name="toEmail"
            v-model="emailFrom"
            readonly
          />
        </div>

        <div class="form-group">
          <label for="emailCc">CC:</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="emailCc"
            name="emailCc"
            placeholder="Digite os e-mails separados por vírgula (ex: exemplo@eloca.com, outro@eloca.com)"
            v-model="emailCc"
          />
        </div>
  
        <input
          type="file"
          ref="fileInput"
          id="fileInput"
          multiple
          style="display: none;"
          @change="onFileChange"
        />
  
        <div class="text-editor-email">
          <ckeditor :editor="editor" :config="editorConfig" v-model="messageBody"/>
        </div>
  
        <label id="attachmentsLabel" class="mt-2 font-weight-bold" v-show="selectedFiles.length > 0">
          Anexos:
        </label>
  
        <ul>
          <li v-for="(file, index) in selectedFiles" :key="index" class="d-flex align-items-center mt-1">
            <span>{{ file.name }} ({{ (file.size / 1048576).toFixed(2) }} MB)</span>
            <button class="btn btn-danger btn-sm ml-2" @click="removeFile(index)">
              <i class="fa fa-trash"></i>
            </button>
          </li>
        </ul>
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-primary btn-sm mr-2"
          id="attachButton"
          @click="openFileDialog"
        >
          <i class="fa fa-paperclip"></i> Anexar Arquivo
        </button>
  
        <button
          type="button"
          class="btn btn-success btn-sm"
          id="sendButton"
          @click="replyEmail"
        >
          <i class="fa fa-paper-plane"></i> Enviar E-mail
        </button>
  
        <button
          type="button"
          class="btn btn-danger btn-sm ml-2"
          @click="closeModal"
        >
          <i class="fa fa-times"></i> Cancelar
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import api from '@/api'

import Modal from '../Modal.vue';

export default {
  name: 'ModalResposta',
  components: {
    Modal,
  },
  data() {
    return {
      selectedFiles: [],
      fileInput: null,
      editor: ClassicEditor,
      messageBody: '',
      emailFrom: '',
      emailSubject: '',
      emailId: '',
      emailThreadId: '',
      emailFromName: '',
      emailCc: '',
      emailAccount: 0,
      editorConfig: {
        toolbar: [
          'undo', 'redo',
          '|',
          'heading',
          '|',
          'fontsize', 'fontColor', 'fontBackgroundColor',
          '|',
          'bold', 'italic',
          '|',
          'link', 'blockQuote',
          '|',
          'bulletedList', 'numberedList', 'outdent', 'indent'
        ],
      }
    };
  },
  beforeMount() {
    setTimeout(() => {
      $('#modalResposta').on('show.bs.modal', (event) => {
        const button = $(event.relatedTarget);
        this.emailFrom = button.data('email-from');
        this.emailSubject = button.data('email-subject');
        this.emailId = button.data('email-id');
        this.emailThreadId = button.data('email-threadid');
        this.emailFromName = button.data('email-fromname');
        this.emailCc = button.data('email-cc');
        this.emailAccount = button.data('email-account');

        $('#modalEmail').modal('hide');
      });
    }, 1)
  },
  mounted() {
    this.fileInput = this.$refs.fileInput;
  },
  methods: {
    closeModal() {
      $('#modalResposta').modal('hide');
      this.messageBody = '';
      this.emailFrom = '';
      this.emailSubject = '';
      this.emailId = '';
      this.emailThreadId = '';
      this.emailFromName = '';
      this.emailCc = '';
      this.emailAccount = 0;
      this.selectedFiles = [];
    },
    openFileDialog() {
      if (this.fileInput) {
        this.fileInput.click();
      }
    },
    onFileChange(event) {
      const newFiles = Array.from(event.target.files);
      this.selectedFiles = [...this.selectedFiles, ...newFiles];
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    async replyEmail() {
      $("#overlayer-loading").addClass("show-overlayer");
      let formData = new FormData();
      formData.append('emailFrom', this.emailFrom ?? '');
      formData.append('emailSubject', this.emailSubject ?? '');
      formData.append('emailId', this.emailId ?? '');
      formData.append('emailThreadId', this.emailThreadId ?? '');
      formData.append('emailFromName', this.emailFromName ?? '');
      formData.append('ccEmails', this.emailCc ?? '');
      formData.append('messageBody', this.messageBody);
      this.selectedFiles.forEach((file) => formData.append('files[]', file));

      await api.post(`email-reply/${this.emailAccount}`, formData, {
        headers: {
          'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        }
      })
        .then((response) => {
          window.showToastSuccess('body', response.data.message);
          this.closeModal();
        })
        .catch((error) => {
          let errorMessage = 'Erro ao enviar email';
          if (error.response.data.message) errorMessage = error.response.data.message;
          if (error.response.data.errors) {
            Object.values(error.response.data.errors).forEach(item => {
              errorMessage = errorMessage.concat('</br>', item);
            });
          }
          window.showToastDanger('body', errorMessage);
        })
        .finally(() => {
          $("#overlayer-loading").removeClass("show-overlayer");
        })
    }
  },
}
</script>
