<template>
  <div class="card custom-form__card card-shadow">
    <div class="card-header">
      <div class="card-with-button">
        <h5>{{ emailsData.length > 0 ? emailsData[0].subject : 'Mensagens' }}</h5>
      </div>
    </div>

    <div class="card-body d-flex flex-column" style="height: 80vh;">
      <div class="container py-1 flex-grow-1" id="emailContainer" style="overflow-y: auto;">
        <div v-for="(email, index) in emailsData" :key="index">
          <EmailMsg :email="email" @toggle-read="toggleReadEmail" />
        </div>
      </div>

      <div class="container py-2 collapse" id="novoEmail">
        <div class="form-group">
          <label for="toEmail">Para:</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="toEmail"
            name="toEmail"
            v-model="emailCliente"
            readonly
          />
        </div>

        <div class="form-group">
          <label for="ccEmails">CC:</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="ccEmails"
            name="ccEmails"
            placeholder="Digite os e-mails separados por vírgula (ex: exemplo@eloca.com, outro@eloca.com)"
            v-model="ccEmails"
          />
        </div>

        <input
          type="file"
          ref="fileInput"
          id="fileInput"
          multiple
          style="display: none;"
          @change="onFileChange"
        />

        <div class="text-editor-email">
          <ckeditor :editor="editor" :config="editorConfig" v-model="messageBody"/>
        </div>

        <label id="attachmentsLabel" class="mt-2 font-weight-bold" v-show="selectedFiles.length > 0">
          Anexos:
        </label>

        <ul>
          <li v-for="(file, index) in selectedFiles" :key="index" class="d-flex align-items-center mt-1">
            <span>{{ file.name }} ({{ (file.size / 1048576).toFixed(2) }} MB)</span>
            <button class="btn btn-danger btn-sm ml-2" @click="removeFile(index)">
              <i class="fa fa-trash"></i>
            </button>
          </li>
        </ul>

        <div class="d-flex justify-content-end mt-2">
          <button
            type="button"
            class="btn btn-primary btn-sm mr-2"
            id="attachButton"
            @click="openFileDialog"
          >
            <i class="fa fa-paperclip"></i> Anexar Arquivo
          </button>

          <button
            type="button"
            class="btn btn-success btn-sm"
            id="sendButton"
            @click="sendEmail"
          >
            <i class="fa fa-paper-plane"></i> Enviar E-mail
          </button>

          <button
            type="button"
            class="btn btn-danger btn-sm ml-2"
            @click="closeNewEmail"
          >
            <i class="fa fa-times"></i> Cancelar
          </button>
        </div>
      </div>

      <div class="container py-2">
        <div class="d-flex justify-content-end mt-2 mr-3">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            id="newEmailButton"
            @click="openNewEmail"
          >
            <i class="fa fa-plus"></i> Novo E-mail
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';

import EmailMsg from "./EmailMsg.vue";

export default {
  name: "EmailConversa",
  components: {
    EmailMsg,
  },
  props: {
    oportunidade_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      oportunidade: {},
      emailsData: [],
      selectedFiles: [],
      fileInput: null,
      editor: ClassicEditor,
      ccEmails: '',
      messageBody: '',
      emailCliente: '',
      editorConfig: {
        toolbar: [
          'undo', 'redo',
          '|',
          'heading',
          '|',
          'fontsize', 'fontColor', 'fontBackgroundColor',
          '|',
          'bold', 'italic',
          '|',
          'link', 'blockQuote',
          '|',
          'bulletedList', 'numberedList', 'outdent', 'indent'
        ],
      }
    };
  },
  created() {
    this.getOportunidade(this.oportunidade_id);
    this.getEmails(this.oportunidade_id);
  },
  mounted() {
    this.fileInput = this.$refs.fileInput;
  },
  methods: {
    toggleReadEmail(emailId) {
      const email = this.emailsData.find((e) => e.id === emailId);
      if (email) {
        email.lido = !email.lido;
      }
    },
    openFileDialog() {
      if (this.fileInput) {
        this.fileInput.click();
      }
    },
    onFileChange(event) {
      const newFiles = Array.from(event.target.files);
      this.selectedFiles = [...this.selectedFiles, ...newFiles];
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
    },
    openNewEmail() {
      this.ccEmails = this.emailsData[this.emailsData.length-1].cc;
      $('#novoEmail').collapse('show');
      $('#newEmailButton').hide();
    },
    closeNewEmail() {
      $('#novoEmail').collapse('hide');
      $('#newEmailButton').show();
      this.clearForm();
    },
    clearForm() {
      this.ccEmails = "";
      this.messageBody = "";
      this.selectedFiles = [];
      this.$refs.fileInput.value = null;
    },
    async sendEmail() {
      $("#overlayer-loading").addClass("show-overlayer");
      let formData = new FormData();
      formData.append('ccEmails', this.ccEmails ?? '');
      formData.append('messageBody', this.messageBody);
      this.selectedFiles.forEach((file) => formData.append('files[]', file));

      await api.post(`email-send/${this.emailsData[0].oportunidade_id}`, formData, {
        headers: {
          'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        }
      })
        .then((response) => {
          window.showToastSuccess('body', response.data.message);
          this.getEmails(this.oportunidade_id);
          this.closeNewEmail();
        })
        .catch((error) => {
          let errorMessage = 'Erro ao enviar email';
          if (error.response.data.message) errorMessage = error.response.data.message;
          if (error.response.data.errors) {
            Object.values(error.response.data.errors).forEach(item => {
              errorMessage = errorMessage.concat('</br>', item);
            });
          }
          window.showToastDanger('body', errorMessage);
        })
        .finally(() => {
          $("#overlayer-loading").removeClass("show-overlayer");
        })
    },
    async getEmails(oportunidade_id) {
      await api.get(`emails/${oportunidade_id}`)
        .then((response) => {
          this.emailsData = response.data.emails;
        })

      setTimeout(function () {
          var container = $("#bloco-email").find('#emailContainer');
          if (container.length > 0) {
              container.scrollTop(container.prop("scrollHeight"));
          }
      }, 100);
    },
    async getOportunidade(oportunidade_id) {
      await api.get(`dados/${oportunidade_id}`)
        .then((response) => {
          this.oportunidade = response.data;
          this.emailCliente = response.data.cliente.email;
        })
    }
  },
};
</script>